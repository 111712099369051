<template>
    <nav class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
        <div class="container my-container">
            <a class="navbar-brand" href="#"><img :src="require('../assets/img/logo.png')" alt="" class="brand_logo"></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div v-if="!user" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="d-flex navbar-nav ms-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link" aria-current="page" to="/">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/#cultivate">Cultivate</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/#educate">Educate</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/#serve">Serve</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/#preserve">Preserve</router-link>
                    </li>
                    <li class="nav-item">
                        <button class="btn btn-donate" type="submit" @click="$router.push('/donate')">Donate</button>
                    </li>
                </ul>
            </div>
            <div v-if="user" class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="d-flex navbar-nav ms-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <router-link class="nav-link" aria-current="page" active-class="active" to="/newsfeed" >Newsfeed</router-link>
                  </li>
                  <li v-if="isChurch" class="nav-item">
                    <router-link  class="nav-link" active-class="active" to="/invite">Invite</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link  class="nav-link" active-class="active" to="/browse">Browse</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link  class="nav-link" active-class="active" to="/share">Share</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link  class="nav-link" active-class="active" to="/discuss">Discuss</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link  class="nav-link" active-class="active" to="/know">Know</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link  class="nav-link" active-class="active" to="/donate">Donate</router-link>
                  </li>
                </ul>
            </div>
            <MenuWidget v-if="user" />
        </div>
    </nav>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { mapState } from 'vuex';
import MenuWidget from './utils/menu.widget.vue';

export default defineComponent({
    computed: {
        ...mapState('auth', ['user'])
    },
    setup() {
        const vm = getCurrentInstance();
        const { Church }  = vm.appContext.provides.$FeathersVuex.api;
        const user = computed(() => vm.appContext.provides.store.state.auth.user);
        const churchData = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: user.value && user.value.id || 0 } }
        }), immediate: true });
        return {
            isChurch: computed(() => {
                const user = vm.appContext.provides.store.state.auth.user;
                if(user)
                    return churchData.items.value.length !== 0;
                return false;
            })
        };
    },
    components: {
        MenuWidget
    }
})
</script>
