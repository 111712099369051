<template>
    <div class="user-widget-menu">
        <ul class="nav-user-widgets">
            <li class="item-widg">
            <svg xmlns="http://www.w3.org/2000/svg" width="23.72" height="23.72" viewBox="0 0 23.72 23.72">
                <g id="search-icon" transform="translate(-1.945 -1.945)">
                <g id="Group_100" data-name="Group 100" transform="translate(1.945 1.945)">
                    <path id="Path_81" data-name="Path 81" d="M25.318,23.646l-6.381-6.381a9.495,9.495,0,1,0-1.672,1.672l6.381,6.381a1.183,1.183,0,1,0,1.672-1.672ZM4.376,11.472a7.1,7.1,0,1,1,7.1,7.1,7.1,7.1,0,0,1-7.1-7.1Z" transform="translate(-1.945 -1.945)"/>
                </g>
                </g>
            </svg>                    
            </li>
            <li class="item-widg">
            <a class="dropdown-toggle" href="#" id="profile-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.339" height="25.192" viewBox="0 0 19.339 25.192">
                <g id="bag-icon" transform="translate(-16.991 -7)">
                    <g id="Group_106" data-name="Group 106" transform="translate(16.991 7)">
                    <path id="Path_84" data-name="Path 84" d="M26.661,959.362a4.7,4.7,0,0,0-4.687,4.687v1.172H19.337a.951.951,0,0,0-.879.806L16.994,983.6a.916.916,0,0,0,.879.952H35.449a.915.915,0,0,0,.879-.952l-1.465-17.576a.911.911,0,0,0-.879-.806H31.347v-1.172A4.7,4.7,0,0,0,26.661,959.362Zm0,1.758a2.9,2.9,0,0,1,2.929,2.929v1.172H23.731v-1.172A2.9,2.9,0,0,1,26.661,961.12Zm-6.509,5.859h1.822v1.373a2.051,2.051,0,1,0,1.758,0v-1.373H29.59v1.373a2.051,2.051,0,1,0,1.758,0v-1.373h1.822L34.487,982.8H18.834Zm2.7,2.929a.293.293,0,1,1-.293.293A.281.281,0,0,1,22.852,969.908Zm7.616,0a.293.293,0,1,1-.293.293A.281.281,0,0,1,30.469,969.908Z" transform="translate(-16.991 -959.362)"/>
                    </g>
                </g>
                </svg>
            </a>
            <ul class="dropdown-menu dropdown-menu-end mt-3 bag-dropdown">
                <div class="my-bag-container">
                <div class="header-bag"><p class="head-title">My Bag</p></div>
                <div class="body-bag">
                    <table class="table table-borderless">
                    <tbody>
                        <tr>
                        <th scope="row">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        </th>
                        <td><img :src="require('../../assets/img/user-widgets/item-sample-bag.png')" alt="" class="img-fluid"></td>
                        <td>
                            <p class="item-title">The Action Bible Guess-It Game</p>
                            <p class="item-author">Sally Lloyd-Jones</p>
                            <div class="item-star-rate">
                            <ul>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="far fa-star"></i></li>
                            </ul>
                            </div>
                        </td>
                        <td class="remove-container">
                            <a href="" class="remove-btn">
                            <i class="far fa-trash-alt"></i>
                            </a>
                            
                        </td>
                        </tr>
                        <tr>
                        <th scope="row">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                        </th>
                        <td><img :src="require('../../assets/img/user-widgets/item-sample-bag.png')" alt="" class="img-fluid"></td>
                        <td>
                            <p class="item-title">The Action Bible Guess-It Game</p>
                            <p class="item-author">Sally Lloyd-Jones</p>
                            <div class="item-star-rate">
                            <ul>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="fas fa-star"></i></li>
                                <li><i class="far fa-star"></i></li>
                            </ul>
                            </div>
                        </td>
                        <td class="remove-container">
                            <a href="" class="remove-btn">
                            <i class="far fa-trash-alt"></i>
                            </a>
                            
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
                <div class="footer-bag"><button type="button" class="btn btn-checkout">Checkout</button></div>
                </div>
            </ul>                            
            </li>
            <li class="item-widg">
            <svg xmlns="http://www.w3.org/2000/svg" width="22.9" height="25.316" viewBox="0 0 22.9 25.316">
                <g id="notif-icon" transform="translate(-211.25 120.25)">
                <g id="Group_191" data-name="Group 191" transform="translate(211.5 -120)">
                    <g id="Group_190" data-name="Group 190">
                    <path id="Path_99" data-name="Path 99" d="M39.408,43.522a3.734,3.734,0,0,1-7.416,0H26.92a2.416,2.416,0,1,1,0-4.831.221.221,0,0,0,.219-.22V33.2a8.567,8.567,0,0,1,5.93-8.152v-.413a2.635,2.635,0,1,1,5.271,0v.413a8.568,8.568,0,0,1,5.93,8.152v5.27a.218.218,0,0,0,.219.22,2.416,2.416,0,1,1,0,4.831Zm-1.332,0H33.324a2.416,2.416,0,0,0,4.752,0ZM33.888,26.181A7.248,7.248,0,0,0,28.453,33.2v5.27a1.538,1.538,0,0,1-1.536,1.538,1.1,1.1,0,1,0,0,2.2H44.48a1.1,1.1,0,1,0,0-2.2,1.535,1.535,0,0,1-1.536-1.538V33.2a7.249,7.249,0,0,0-5.435-7.019l-.495-.127V24.636a1.318,1.318,0,1,0-2.635,0v1.418Z" transform="translate(-24.5 -22)" stroke="#000" stroke-width="0.5"/>
                    </g>
                </g>
                </g>
            </svg>
            </li>
            <li class="item-widg">
            <a class="dropdown-toggle" href="#" id="profile-dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg xmlns="http://www.w3.org/2000/svg" width="20.163" height="25.192" viewBox="0 0 20.163 25.192">
                <g id="user-icon" transform="translate(-117.792 -19.494)">
                    <g id="Group_105" data-name="Group 105" transform="translate(117.792 19.494)">
                    <g id="Group_102" data-name="Group 102" transform="translate(3.828)">
                        <g id="Group_101" data-name="Group 101">
                        <path id="Path_82" data-name="Path 82" d="M277.676,25.75a5.446,5.446,0,0,1-.043.675c.013-.1.028-.2.041-.307a5.318,5.318,0,0,1-.35,1.3c.038-.092.077-.184.115-.274a5.2,5.2,0,0,1-.66,1.136l.179-.233a5.18,5.18,0,0,1-.946.946l.233-.179a5.244,5.244,0,0,1-1.136.66l.274-.115a5.289,5.289,0,0,1-1.3.35l.307-.041a5.189,5.189,0,0,1-1.35,0l.307.041a5.319,5.319,0,0,1-1.3-.35l.274.115a5.2,5.2,0,0,1-1.136-.66l.233.179a5.181,5.181,0,0,1-.946-.946l.179.233a5.242,5.242,0,0,1-.66-1.136c.038.092.077.184.115.274a5.288,5.288,0,0,1-.35-1.3c.013.1.028.2.041.307a5.185,5.185,0,0,1,0-1.35c-.013.1-.028.2-.041.307a5.319,5.319,0,0,1,.35-1.3c-.038.092-.077.184-.115.274a5.2,5.2,0,0,1,.66-1.136l-.179.233a5.18,5.18,0,0,1,.946-.946l-.233.179a5.243,5.243,0,0,1,1.136-.66l-.274.115a5.289,5.289,0,0,1,1.3-.35l-.307.041a5.189,5.189,0,0,1,1.35,0l-.307-.041a5.319,5.319,0,0,1,1.3.35l-.274-.115a5.2,5.2,0,0,1,1.136.66l-.233-.179a5.18,5.18,0,0,1,.946.946l-.179-.233a5.242,5.242,0,0,1,.66,1.136c-.038-.092-.077-.184-.115-.274a5.287,5.287,0,0,1,.35,1.3c-.013-.1-.028-.2-.041-.307a5.446,5.446,0,0,1,.043.675,1.153,1.153,0,0,0,1.151,1.151,1.154,1.154,0,0,0,.813-.338,1.193,1.193,0,0,0,.338-.813,6.2,6.2,0,0,0-1.11-3.552,5.882,5.882,0,0,0-1.458-1.491,6.12,6.12,0,0,0-1.632-.862,6.233,6.233,0,0,0-6.816,1.857,6.912,6.912,0,0,0-.614.852,7.26,7.26,0,0,0-.458.954,6.129,6.129,0,0,0-.407,1.852A6.237,6.237,0,0,0,271.05,31.4a5.816,5.816,0,0,0,2.072.57,6.238,6.238,0,0,0,6.762-5.136,7.035,7.035,0,0,0,.1-1.084,1.153,1.153,0,0,0-2.307,0Z" transform="translate(-267.468 -19.494)"/>
                        </g>
                    </g>
                    <g id="Group_104" data-name="Group 104" transform="translate(0 13.439)">
                        <g id="Group_103" data-name="Group 103">
                        <path id="Path_83" data-name="Path 83" d="M135.345,554.387H122.168c-.583,0-1.166.005-1.749,0a1.6,1.6,0,0,1-.192-.01l.307.041a1.519,1.519,0,0,1-.4-.107l.274.115a1.533,1.533,0,0,1-.317-.189l.233.179a1.681,1.681,0,0,1-.261-.261l.179.233a1.733,1.733,0,0,1-.189-.317c.038.092.077.184.115.274a1.517,1.517,0,0,1-.107-.4c.013.1.028.2.041.307a7.64,7.64,0,0,1-.01-.8,8.419,8.419,0,0,1,.064-1.077c-.013.1-.028.2-.041.307a7.333,7.333,0,0,1,.5-1.831c-.038.092-.077.184-.115.274a7.375,7.375,0,0,1,.957-1.634l-.179.233a7.434,7.434,0,0,1,1.307-1.307l-.233.179a7.478,7.478,0,0,1,1.634-.957l-.274.115a7.336,7.336,0,0,1,1.831-.5l-.307.041a15.087,15.087,0,0,1,1.954-.064h2.176a8.52,8.52,0,0,1,1.143.064l-.307-.041a7.336,7.336,0,0,1,1.831.5l-.274-.115a7.377,7.377,0,0,1,1.634.957l-.233-.179a7.433,7.433,0,0,1,1.307,1.307l-.179-.233a7.475,7.475,0,0,1,.957,1.634c-.038-.092-.077-.184-.115-.274a7.334,7.334,0,0,1,.5,1.831c-.013-.1-.028-.2-.041-.307a8.419,8.419,0,0,1,.064,1.077,7.638,7.638,0,0,1-.01.8c.013-.1.028-.2.041-.307a1.517,1.517,0,0,1-.107.4c.038-.092.077-.184.115-.274a1.532,1.532,0,0,1-.189.317l.179-.233a1.678,1.678,0,0,1-.261.261l.233-.179a1.733,1.733,0,0,1-.317.189l.274-.115a1.518,1.518,0,0,1-.4.107l.307-.041c-.059.005-.118.01-.176.01a1.153,1.153,0,0,0-1.151,1.151,1.154,1.154,0,0,0,.338.813,1.192,1.192,0,0,0,.813.338,2.648,2.648,0,0,0,2.258-1.3,2.685,2.685,0,0,0,.35-1.3c0-.141,0-.281,0-.422a8.881,8.881,0,0,0-.527-3.228,8.446,8.446,0,0,0-5.292-5.084,8.846,8.846,0,0,0-2.8-.409h-2.176a15.21,15.21,0,0,0-1.931.064,8.323,8.323,0,0,0-1.882.437,8.577,8.577,0,0,0-1.7.839,8.664,8.664,0,0,0-2.629,2.693,8.506,8.506,0,0,0-1.2,3.655c-.041.409-.036.821-.036,1.233a4.416,4.416,0,0,0,.061.775,2.527,2.527,0,0,0,.614,1.192,2.61,2.61,0,0,0,1.936.864H135.34a1.153,1.153,0,0,0,1.151-1.151,1.154,1.154,0,0,0-.338-.813A1.171,1.171,0,0,0,135.345,554.387Z" transform="translate(-117.792 -544.935)"/>
                        </g>
                    </g>
                    </g>
                </g>
                </svg> 
            </a>
            <ul class="dropdown-menu dropdown-menu-end mt-3 profile-settings-dropdown">
                <div class="profile-settings-menu">
                <div class="user-profile-container" @click="gotoProfilePage">
                    <div class="img-container">
                        <img :src="user.avatar || require('../../assets/img/feeds/user_img_placeholder.png')" alt="" class="img-fluid">
                    </div>
                    <h4 v-if="!isChurch">{{ user.fname }} {{ user.lname }}</h4>
                    <h4 v-else>{{ churchData[0].name }}</h4>
                    <p class="mem-stat">{{ isChurch ? 'Church Member' : 'Member' }}</p>
                </div>
                <ul class="profile-settings-list">
                    <li class='profile-settings-item' @click="$router.push('/profile')">My profile</li>
                    <li class='profile-settings-item'>Message</li>
                    <li class='profile-settings-item' @click="logout">Logout</li>
                </ul>
                </div>
            </ul>                   
            </li>
        </ul>
    </div>
</template>

<script>
import { useFind } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { mapState, mapActions } from 'vuex';

export default defineComponent({
    setup() {
        const vm = getCurrentInstance();
        const { Church }  = vm.appContext.provides.$FeathersVuex.api;
        const user = computed(() => vm.appContext.provides.store.state.auth.user);
        const churchData = useFind({ model: Church, params: computed(() => {
            return { query: { owner_id: user.value && user.value.id || 0 } }
        }), immediate: true });
        return {
            churchData: churchData.items,
            isChurch: computed(() => {
                const user = vm.appContext.provides.store.state.auth.user;
                if(user)
                    return churchData.items.value.length !== 0;
                return false;
            })
        };
    },
    computed: {
        ...mapState('auth', ['user'])
    },
    methods: {
        async doLogout() {
            this.$router.push('/');
            await this.logout();
        },
        async gotoProfilePage() {
            this.$router.push(`/profile/${this.user.id}`);
        },
        ...mapActions('auth', ['logout'])
    }
})
</script>

<style scoped>
.user-profile-container .img-container {
    cursor: pointer;
}
.user-profile-container h4 {
    cursor: pointer;
}
</style>