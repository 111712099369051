import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import DKToast from 'vue-dk-toast';

import './assets/css/main.css'

import Navbar from './components/navbar.component.vue';
import Footer from './components/footer.component.vue';
import store from './store';

import { FeathersVuex, models } from './feathers-client'

// Global Components
const app = createApp(App)
    // Store
    .use(FeathersVuex)
    .use(DKToast, {
        duration: 5000,
        positionY: 'top', // 'top' or 'bottom'
        positionX: 'center', // 'right' or 'left'
        styles: {
            color: '#fff',
            backgroundColor: '#6C782A',
            borderRadius: '25px',
        }
    })
    .use(store)

    .component('qui-navbar', Navbar)
    .component('qui-footer', Footer)

    // Entry
    .use(router);

app.config.globalProperties.$FeathersVuex = models;

app.mount('#app');