<template>
  <qui-navbar />
  <router-view v-if="!loading" />
  <qui-footer />
</template>

<script>
import { useFind, useGet } from '@feathersjs/vuex';
import { computed, defineComponent, getCurrentInstance, watch } from 'vue';
import { mapState, mapActions } from 'vuex';
import Router from './router/index';

export default defineComponent({
  data() {
    return {
      loading: true 
    };
  },
  watch: {
    user() {
      this.routeConfirmation(this.$route);
    },
    $route(route) {
      if(!this.loading) 
        this.routeConfirmation(route);
    }
  },
  setup() {
    const result = {};
    const keys = Object.keys(Router['validations']);
    const vm = getCurrentInstance();

    for(let i of keys) {
      result[i] = Router['validations'][i](vm, {
        computed,
        useFind,
        useGet
      });

      watch(result[i], (value) => {
        if(!vm.data.loading && value !== null) {
          vm['ctx'].routeConfirmation(vm.appContext.config.globalProperties.$route);
        }
      });
    }
    return result;
  },
  computed: {
    router() {
      return Router['rules'];
    },
    ...mapState('auth', ['user'])
  },
  async created() {
    const token = window.localStorage.getItem('feathers-jwt')
    if(token) {
      await this.authenticate({
        strategy: 'jwt',
        accessToken: token
      });
    } else {
      this.redirectToHome();
    }
    this.loading = false;
  },
  methods: {
    redirectToHome() {
      if(['/', '/join-us', '/know'].indexOf(this.$route.path) === -1) {
        this.$router.push('/');
      }
    },
    routeConfirmation(route) {
      if(this.user) {
        this.routerCheck(route, this.router.in);
      } else {
        this.routerCheck(route, this.router.out);
      }
    },
    routerCheck(route, list) {
      const targetRoute = list[route.name];
      if(targetRoute) {
        if(targetRoute.to) {
          this.$router.push(targetRoute.to);
        } else if(targetRoute.validation) {
          for(let i of targetRoute.validation) {
            if(this[i.name] !== null && this[i.name] && i.to) {
              this.$router.push(i.to);
              break;
            }
          }
        }
      }
    },
    ...mapActions('auth', ['authenticate'])
  }
})
</script>
