import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class ChurchAdmin extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'ChurchAdmin'
  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}
const servicePath = 'church-admin'
const servicePlugin = makeServicePlugin({
  Model: ChurchAdmin,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [ async context => {
      const { result, app } = context;
      await Promise.all((result.data || result).map(async i => {
        i.user = await app.service('users').get(i.user_id);
      }));
      return context;
    } ],
    get: [],
    create: [ async context => {
      const { result, app } = context;
      result.user = await app.service('users').get(result.user_id);
      return context;
    } ],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin