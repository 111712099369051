export default function(vm, { useFind, computed }) {
    const provides = vm.appContext.provides;
    const { Church }  = provides.$FeathersVuex.api;
    const user = computed(() => provides.store.state.auth.user);
    const churchData = useFind({ model: Church, params: computed(() => {
        return { query: { owner_id: user.value && user.value.id || 0 } }
    }), immediate: true });
    return computed(() => {
      const user = provides.store.state.auth.user;
      if(churchData.isPending.value || !user) {
        return null;
      }
      return churchData.items.value.length === 0;
    });
}