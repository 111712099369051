<template>
    <div class="modal fade" id="modal_app_notice" tabindex="-1" aria-labelledby="modal_app_noticeLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-app-notice-content">
                <div class="modal-body">
                    <div class="head-mod-container">

                        <div class="icon-stat-container">
                            <img class="img-fluid" :src="require('../../assets/img/home/app_launch.png')" alt="">
                        </div>

                        <h3 class="head-modal-title">
                            The QuiverShare app will be 
                            available soon. Launch schedule 
                            will be announced as soon 
                            as finalized.
                        </h3>
                    </div>
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-ok-close" data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>