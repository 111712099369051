import feathersClient, { makeServicePlugin, BaseModel } from '../../feathers-client'

class UserChildrens extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'UserChildrens'
  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}
const servicePath = 'user-childrens'
const servicePlugin = makeServicePlugin({
  Model: UserChildrens,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [ async context => {
      const { app, result } = context;
      
      await Promise.all((result.data || result).map(async item => {
        const grade = await app.service('children-school-grade').get(item.grade_id);
        item.grade = grade.name;
      }));

      return context;
    } ],
    get: [],
    create: [ async context => {
      const { app, result } = context;

      const grade = await app.service('children-school-grade').get(result.grade_id);
      result.grade = grade.name;

      return context;
    } ],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin