import { createRouter, createWebHistory } from 'vue-router';
import isNotChurch from './validations/isNotChurch';

const routes = [
  { name: 'Home', path: '/', component: () => import('../views/Home.vue') },
  { name: 'About', path: '/about', component: () => import('../views/About.vue') },
  { name: 'JoinUs', path: '/join-us', component: () => import('../views/JoinUs.vue') },
  { name: 'JoinUsHash', path: '/join-us/:hashdata', component: () => import('../views/JoinUs.vue') },
  { name: 'Newsfeed', path: '/newsfeed', component: () => import('../views/Newsfeed.vue') },
  { name: 'Invite', path: '/invite', component: () => import('../views/Invite.vue') },
  { name: 'Browse', path: '/browse', component: () => import('../views/Browse.vue') },
  { name: 'Share', path: '/share', component: () => import('../views/Share.vue') },
  { name: 'Discuss', path: '/discuss', component: () => import('../views/Discuss.vue') },
  { name: 'Know', path: '/know', component: () => import('../views/Know.vue') },
  { name: 'Donate', path: '/donate', component: () => import('../views/Donate.vue') },
  { name: 'PersonalProfile', path: '/profile/:id', component: () => import('../views/PersonalProfile.vue') },
  { name: 'Profile', path: '/profile', component: () => import('../views/Profile.vue') },
  { name: 'ResetPassword', path: '/reset-password/:hashdata', component: () => import('../views/ResetPassword.vue') },
  { name: 'ContactUs', path: '/contact-us', component: () => import('../views/ContactUs.vue') },
  { name: 'ChurchMembers', path: '/church-members', component: () => import('../views/ChurchMembers.vue') },
  { name: 'ConfirmNewRequest', path: '/confirm-users', component: () => import('../views/ConfirmNewRequest.vue') },
  { name: 'MyLikes', path: '/my-likes', component: () => import('../views/MyLikes.vue') },
  { name: 'MyReviews', path: '/my-reviews', component: () => import('../views/MyReview.vue') },
  { name: 'MyArticles', path: '/my-articles', component: () => import('../views/MyArticles.vue') },
  { name: 'ArticleIFollow', path: '/article-i-follow', component: () => import('../views/ArticleIFollow.vue') }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
});

router.rules = {
  in: {
    'Home': { to: '/newsfeed' },
    'JoinUs': { to: '/newsfeed' },
    'JoinUsHash': { to: '/newsfeed' },
    'Invite': { validation: [
      { name: 'isNotChurch', to: '/newsfeed' }
    ] },
    'ChurchMembers': { validation: [
      { name: 'isNotChurch', to: '/newsfeed' }
    ] },
    'ConfirmNewRequest': { validation: [
      { name: 'isNotChurch', to: '/newsfeed' }
    ] }
  },
  out: {
    'Newsfeed': { to: '/' },
    'Invite': { to: '/' },
    'Browse': { to: '/' },
    'Share': { to: '/' },
    'Discuss': { to: '/' },
    'Profile': { to: '/' },
    'ChurchMembers': { to: '/' },
    'ConfirmNewRequest': { to: '/' }
  }
};

router.validations = {
  isNotChurch
};

export default router
