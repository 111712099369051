import { createStore } from 'vuex'
import auth from './store.auth'

const requireModule = require.context(
    // The path where the service modules live
    './services',
    // Whether to look in subfolders
    false,
    // Only include .js files (prevents duplicate imports`)
    /\.js$/
);

const servicePlugins = requireModule
    .keys()
    .map(modulePath => requireModule(modulePath).default);

export default createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
    plugins: [...servicePlugins, auth]
});
