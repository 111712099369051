<template>
  <footer class="footer">
    <div class="rec-divider"></div>
    <div class="container my-container">
      <div class="footer-container">
        <div class="row">
          <div class="col-4 col-md-4">
            <img :src="require('../assets/img/home/md-logo.png')" class="img-fluid" alt="" />
            <h3>Cultivate. Educate. <br />Serve. Preserve.</h3>
            <p>No wasted arrow. QuiverShare.</p>
            <p class="copyright">
              Copyright © 2021 QuiverShare. All rights reserved.
            </p>
          </div>
          <div class="col-4 col-md-4">
            <p class="label-link">About</p>
            <ul>
              <li><router-link to="/know#our-ministry">Our Ministry</router-link></li>
              <li><router-link to="/know#our-declaration-of-faith">Our Declaration of Faith</router-link></li>
              <li><router-link to="/know#our-platform-process">Our Platform &amp; Process</router-link></li>
              <li><router-link to="/know#our-regulating-rules">Our Regulating Rules</router-link></li>
              <li><router-link to="/know#our-advisers">Our Advisers</router-link></li>
              <li><router-link to="/know#our-company">Our Company</router-link></li>
            </ul>
          </div>
          <div class="col-4 col-md-4">
            <p class="label-link">Support</p>
            <ul>
              <li><router-link to="/know#terms-of-use">Terms and Conditions</router-link></li>
              <li><router-link to="/know#privacy-policy">Privacy Policy</router-link></li>
              <li><a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#modal_app_notice">Download App</a></li>
              <li><router-link to="/know#faq">FAQ</router-link></li>
              <li><router-link to="/donate">Donate</router-link></li>
              <li><router-link to="/contact-us">Contact Us</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <ModalAppNotice />
</template>

<script>
import ModalAppNotice from '../components/modals.component/modal-app-notice.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    
  },
  components: {
    ModalAppNotice
  }
})
</script>
